<template>
  <div id="footer" class="ui centered grid">
    <div id="menu" class="ui secondary pointing menu">
      <router-link to="/" class="item">
        Home
      </router-link>
      <router-link :to="{ name: 'About' }" class="item">
        About
      </router-link>
      <router-link :to="{ name: 'Contact' }" class="item">
        Contact Me
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#socials {
  margin-top: 5px;
}
i {
  color: white;
  transition: all 0.1s ease-in-out;
}
i:hover {
  transform: scale(1.1);
}
#menu {
  border-bottom: none;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 33vh;
}
#footer .item {
  color: rgba(255, 255, 255, 1);
  transition: all 0.1s ease-in-out;
  margin-left: 2em;
  font-weight: bold;
}
#footer .item:hover {
  transform: scale(1.1);
  padding-right: 1.1em;
}
#footer a.router-link-exact-active {
  transition: none;
  border-bottom-color: white;
}
#footer a.router-link-exact-active:hover {
  transform: none;
  padding-right: 1.14286em;
  border-bottom-color: white;
}

/* 
** MEDIA QUERIES
*/

/* iPhone 5/SE */
@media only screen and (min-height: 560px) and (min-width: 320px) and (max-height: 570px) {
  #footer {
    margin-bottom: 9vh;
    margin-right: 6vw;
  }
  #socials {
    margin-top: -2vh;
    margin-left: 2vw;
  }
}
/* Galaxy S5 */
@media only screen and (min-height: 640px) and (min-width: 360px) and (max-height: 650px) {
  #footer {
    margin-bottom: 8vh;
  }
}
/* iPhone 6/7/8 Plus */
@media only screen and (min-height: 730px) and (min-width: 410px) and (max-height: 740px) {
  #footer {
    margin-bottom: 10vh;
  }
  #socials {
    margin-top: -1vh;
  }
}
/* iPhone X */
@media only screen and (min-height: 810px) and (min-width: 375px) and (max-height: 820px) {
  #footer {
    margin-bottom: 10vh;
  }
  #socials {
    margin-top: -1vh;
  }
}
/* Galaxy S10e */
@media only screen and (min-height: 610px) and (min-width: 360px) and (max-height: 650px) {
  #footer {
    margin-bottom: 6vh;
    margin-right: 2vw;
  }
  #socials {
    margin-top: -2vh;
    margin-left: 0;
  }
}
/* Galaxy S10 */
@media only screen and (min-height: 760px) and (min-width: 360px) and (max-height: 800px) {
  #footer {
    margin-bottom: 8vh;
  }
  #socials {
    margin-top: -2vh;
  }
}
/* Galaxy S20 */
@media only screen and (min-height: 800px) and (min-width: 360px) and (max-height: 810px) {
  #footer {
    margin-bottom: 10vh;
  }
}
/* Galaxy Fold */
@media only screen and (min-height: 650px) and (min-width: 280px) and (max-height: 700px) {
  #footer {
    margin-left: 10vw;
    margin-right: 8vw;
    margin-bottom: 8vh;
  }
  #socials {
    margin-top: -2vh;
  }
  #footer .item {
    margin-left: 0;
  }
}
/* iPhone 6/7/8 */
@media only screen and (min-height: 660px) and (min-width: 370px) and (max-height: 670px) {
  #footer {
    margin-left: 13vw;
    margin-bottom: 7vh;
  }
  #socials {
    margin-top: -2vh;
    margin-right: 1vw;
  }
}
/* Surface Duo */
@media only screen and (min-height: 720px) and (min-width: 540px) and (max-width: 550px) {
  #footer {
    margin-left: 0vw;
    margin-bottom: 18vh;
  }
}
/* iPad */
@media only screen and (min-height: 1020px) and (min-width: 760px) and (max-width: 800px) {
  #footer {
    margin-left: 0vw;
    margin-bottom: 30vh;
  }
}

/* Pixel 2 XL */
@media only screen and (min-height: 820px) and (min-width: 410px) and (max-height: 825px) {
  #footer {
    margin-bottom: 10vh;
  }
}

/* iPad Pro */
@media only screen and (min-height: 1350px) and (min-width: 1020px) and (max-height: 1380px) {
  #footer {
    margin-bottom: 38vh;
  }
}

/* CATCH ALL */
@media only screen and (max-height: 1380px) {
  #footer {
    margin-bottom: 30vh;
  }
}

/* CATCH ALL */
@media only screen and (max-height: 770px) {
  #footer {
    margin-bottom: 10vh;
  }
  #socials {
    margin-top: -2vh;
  }
}

/* CATCH ALL */
@media only screen and (max-width: 990px) {
  #footer {
    margin-bottom: 8vh;
  }
  #socials {
    margin-top: -2vh;
  }
}
</style>
