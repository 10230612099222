<template>
  <div class="contact-content ui center aligned container">
    <div class="center aligned column">
      <h1>Get in touch?</h1>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        @submit.prevent="handleSubmit"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label>Name: </label>
        <input type="text" required v-model="form.name" />
        <label>Email: </label>
        <input type="email" required v-model="form.email" />
        <label>Message: </label><br />
        <textarea required v-model="form.message"></textarea><br />
        <button id="submit" class="fluid ui inverted button">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Contact',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
    },
    handleSubmit() {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };

      axios
        .post(
          '/',
          this.encode({
            'form-name': 'contact',
            ...this.form
          }),
          axiosConfig
        )
        .then(() => this.$router.push({ name: 'SubmissionSuccess' }))
        .catch(() => this.$router.push({ name: 'SubmissionFail' }));
    }
  }
};
</script>

<style scoped>
.contact-content {
  display: flex;
  justify-content: center;
  border: 3px solid #f1f1f1;
  padding: 20px;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity/see-through */
  animation: fadeIn 0.5s;
  margin-bottom: 17em;
  width: 30vw;
}
label {
  color: white;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  outline: none;
}
input {
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid white;
  color: black;
  border-radius: 6px;
  outline: none;
}
textarea {
  resize: none;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid white;
  color: black;
  border-radius: 6px;
  outline: none;
}
#submit {
  margin-top: 1em;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
** MEDIA QUERIES
*/

/* Surface Duo */
@media only screen and (min-height: 720px) and (min-width: 540px) and (max-width: 550px) {
  .contact-content {
    width: 50vw;
  }
}
/* iPad */
@media only screen and (min-height: 1020px) and (min-width: 760px) and (max-width: 800px) {
  .contact-content {
    width: 50vw;
  }
}
/* Pixel 2 XL */
@media only screen and (min-height: 820px) and (min-width: 410px) and (max-height: 825px) {
  .contact-content {
    width: 50vw;
  }
}
/* iPad Pro */
@media only screen and (min-height: 1350px) and (min-width: 1020px) and (max-height: 1380px) {
  .contact-content {
    width: 50vw;
  }
}
</style>
