<template>
  <div class="about-content ui center aligned container">
    <div class="center aligned column">
      <h2>Work in progress.</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
};
</script>

<style scoped>
p {
  font-size: 1.1em;
}
.about-content {
  display: flex;
  justify-content: center;
  border: 3px solid #f1f1f1;
  padding: 20px;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity/see-through */
  color: white;
  animation: fadeIn 0.5s;
  margin-bottom: 6.5em;
  overflow-y: auto;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-height: 1100px) and (max-width: 770px) {
  .home-content {
    padding-right: 14vw;
  }
}
@media only screen and (max-height: 800px) and (max-width: 600px) {
  .home-content {
    padding-right: 2vw;
  }
}
@media only screen and (max-height: 740px) and (max-width: 420px) {
  .home-content {
    padding-right: 0vw;
  }
}
@media only screen and (max-width: 600px) {
  .home-content {
    padding: 10px;
  }
}
/* Surface Duo */
@media only screen and (max-height: 720px) and (max-width: 540px) {
  .home-content {
    padding-right: 0vw;
  }
}
/* Galaxy Fold */
@media only screen and (min-height: 650px) and (min-width: 280px) {
  .home-content {
    padding-right: 2vw;
  }
}
</style>
