<template>
  <div class="home-content ui centered grid">
    <div class="center aligned column">
      <h1>Under construction</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
};
</script>

<style scoped>
.home-content {
  display: flex;
  justify-content: center;
  /* Testing out the CSS above this comment. Uncomment the below styles later if applicable*/

  /* position: relative; */
  /* margin-top: 25vh; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* z-index: 2; */
  /* width: 50vw; */
  border: 3px solid #f1f1f1;
  padding: 20px;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  animation: fadeIn 0.5s;
}
h1 {
  font-size: 5em;
  color: white;
  animation: fadeIn 1s;
}
h2 {
  color: white;
  animation: fadeIn 1.5s;
  font-family: 'JetBrains Mono', monospace;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-height: 1100px) and (max-width: 770px) {
  .home-content {
    padding-right: 14vw;
  }
}
@media only screen and (max-height: 800px) and (max-width: 600px) {
  .home-content {
    padding-right: 2vw;
  }
}
@media only screen and (max-height: 740px) and (max-width: 420px) {
  .home-content {
    padding-right: 0vw;
  }
}
@media only screen and (max-width: 600px) {
  .home-content {
    padding: 10px;
  }
}
/* Surface Duo */
@media only screen and (max-height: 720px) and (max-width: 540px) {
  .home-content {
    padding-right: 0vw;
  }
}
/* Galaxy Fold */
@media only screen and (min-height: 650px) and (min-width: 280px) {
  .home-content {
    padding-right: 2vw;
  }
}
</style>
