<template>
  <div class="success-content ui center aligned container">
    <div class="center aligned column">
      <h1>Oops, looks like something went wrong!</h1>
      <i class="massive close icon red"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmissionFail'
};
</script>

<style scoped>
.success-content {
  display: flex;
  justify-content: center;
  border: 3px solid #f1f1f1;
  padding: 20px;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity/see-through */
  color: white;
  animation: fadeIn 2s;
  margin-bottom: 6.5em;
  overflow-y: auto;
  width: 30vw;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
