<template>
  <div class="main-wrap">
    <img
      class="main-bg"
      src="./assets/daniel-leone-g30P1zcOzXo-unsplash.jpg"
      alt="Photo by Daniel Leone from Unsplash"
    />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue';

export default {
  components: {
    Footer
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
.main-wrap {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  /* Testing out the CSS above this comment */
  position: relative;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}
.main-bg {
  z-index: -1;
  filter: blur(8px);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
@media only screen and (max-width: 300px) {
  .main-wrap {
    min-height: 115vh;
  }
}
</style>
